<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>About <span>destek</span></h2>

            <p>
                Founded by Mandar Lande and Anirudha Kotgire in 2017, Destek Infosolutions is a leading software consulting and development company. We deliver tailored IT solutions and innovative strategies that drive digital transformation, empowering organizations to thrive in a competitive market.

            </p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>
                            We Provide <span>IT Solutions & Services</span>
                        </h2>
                        <p>
                            Destek Infolutions is a software company that
                            focuses on providing comprehensive IT solutions and
                            services. Their strategy likely revolves around
                            several key elements typical to companies in this
                            industry
                        </p>
                    </div>
                    <ul class="features-list">
                        <li>
                            <i class="fas fa-check"></i>Client-Centric Approach
                        </li>
                        <li>
                            <i class="fas fa-check"></i>Innovation and
                            Technology
                        </li>
                        <li>
                            <i class="fas fa-check"></i>Agility and Adaptability
                        </li>
                        <li>
                            <i class="fas fa-check"></i>Quality and Reliability
                        </li>
                       
                        <li><i class="fas fa-check"></i>Market Expansion</li>
                        
                        <li>
                            <i class="fas fa-check"></i>Customer Support and
                            Service
                        </li>
                    </ul>
                    
                    <p>We bring together experts in technology consulting, UI/UX design, app development, web design, digital marketing, and IoT to deliver customized IT solutions that drive business transformation</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/About-Destek.png" alt="about" />
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
