<div id="pricing" class="pricing-area pt-100 pb-70 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Our Products</h2>
            <p>
                Innovative Solutions and Cutting-Edge Technology for Your Business Success.
            </p>
        </div>
        <div class="pricing-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="pricingTable">
                        <h3 class="title">WAAYU</h3>
                        <div class="pricing-content">
                            <div class="price-value">
                                <img src="assets/img/waayu.png" alt="WAAYU" />
                            </div>
                            <div class="price-details">
                                <p>
                                    Waayu is a user-friendly app designed to streamline food ordering and delivery, Waayu ensures timely and accurate delivery from your favorite restaurants.
                                </p>
                            </div>
                        </div>
                        <a href="https://waayu.app/" target="_blank" class="btn btn-primary">Know More</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="pricingTable">
                        <h3 class="middle-title">DHAK DHAK</h3>
                        <div class="pricing-content">
                            <div class="price-value">
                                <img
                                    src="assets/img/dhak-dhak-3.jpg"
                                    alt="DHAK DHAK"
                                />
                            </div>
                            <div class="price-details">
                                <p>
                                    Dhak Dhak is a vibrant social media app that lets users create, share, and explore short, engaging videos. With endless entertainment to your fingertips.
                                </p>
                            </div>
                        </div>
                        <a href="https://dhakdhak.world/" target="_blank" class="btn btn-dark">Know More</a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="pricingTable">
                        <h3 class="title">MERI DUKAN</h3>
                        <div class="pricing-content">
                            <div class="price-value">
                                <img
                                    src="assets/img/meri-dukan.png"
                                    alt="MERI DUKAN"
                                />
                            </div>
                            <div class="price-details">
                                <p>
                                    Meri Dukaan aids small retailers in digitizing, connecting with customers via apps and websites, taking online orders and payments.
                                </p>
                            </div>
                        </div>
                        <a href="https://meridukaan.online/" target="_blank" class="btn btn-primary">Know More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image" /></div>
</div>
