<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/about-strategy-2.png" alt="image" />
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Highly Creative Solutions</h4>
                        <h2>About <span>Strategy</span></h2>
                        <p>
                            Destek Infolutions' strategy is likely focused
                            on leveraging technology, understanding client needs,
                            maintaining high standards of quality, and fostering
                            innovation to achieve sustainable growth in the
                            competitive IT services market.
                        </p>
                    </div>
                    <ul class="features-list">
                        <li>
                            <i class="fas fa-check"></i>Requirement Discussions
                        </li>
                        <li>
                            <i class="fas fa-check"></i>Digital Maturity
                            Analysis
                        </li>
                        <li>
                            <i class="fas fa-check"></i>Crafting Digital
                            Strategy
                        </li>
                        <li>
                            <i class="fas fa-check"></i>Crafting Digital
                            Transformation PlanQuality and Reliability
                        </li>
                        <li>
                            <i class="fas fa-check"></i>Establish Required
                            Foundation
                        </li>
                        <li>
                            <i class="fas fa-check"></i>Execution of
                            Transformation Activities
                        </li>
                        <li>
                            <i class="fas fa-check"></i>Analytics & Periodic
                            Review
                        </li>
                    </ul>
                    <p>
                        <strong>You do the business, We run the business.</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image" /></div>
</div>
