<div id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>Our News</h4>
            <h2>Latest Blog <span>Posts</span></h2>
            <p>
                Read Our Blog for Insights on Technology, Innovation, and
                Business.
            </p>
        </div>
        <div class="blog-slides">
            <owl-carousel-o [options]="blogSlides">
                <ng-template carouselSlide>
                    <div class="single-blog-item bg1">
                        <span>Business Success</span>
                        <h3>
                            <a routerLink="/blog-details"
                                >Strategies for Business Success</a
                            >
                        </h3>
                        <p>
                            In our latest blog post, we explore effective
                            strategies for businesses to thrive in a remote work
                            environment. Discover tips on maintaining productivity...
                        </p>
                        <a
                            routerLink="/blog-details"
                            title="Read More"
                            class="link-btn"
                            ><i class="fas fa-arrow-right"></i
                        ></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg2">
                        <span>AI</span>
                        <h3>
                            <a routerLink="/blog-details"
                                >The Rise of AI in Customer Service:</a
                            >
                        </h3>
                        <p>
                            Explore the transformative impact of Artificial
                            Intelligence (AI) on customer service operations in
                            our latest blog post. Learn how AI-powered chatbots
                            and virtual...
                        </p>
                        <a
                            routerLink="/blog-details"
                            title="Read More"
                            class="link-btn"
                            ><i class="fas fa-arrow-right"></i
                        ></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg3">
                        <span>Future Of IT</span>
                        <h3>
                            <a routerLink="/blog-details"
                                >The Future of IT Solutions: Trends to Watch in
                                2024</a
                            >
                        </h3>
                        <p>
                            The IT industry is constantly evolving, with new
                            trends and technologies shaping the way businesses
                            operate. Staying ahead...
                        </p>
                        <a
                            routerLink="/blog-details"
                            title="Read More"
                            class="link-btn"
                            ><i class="fas fa-arrow-right"></i
                        ></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg4">
                        <span>Business Growth</span>
                        <h3>
                            <a routerLink="/blog-details">
                                How IT Solutions Can Drive Business Growth
                            </a>
                        </h3>
                        <p>
                            In today’s fast-paced digital world, businesses are
                            under constant pressure to innovate and adapt to
                            stay competit...
                        </p>
                        <a
                            routerLink="/blog-details"
                            title="Read More"
                            class="link-btn"
                            ><i class="fas fa-arrow-right"></i
                        ></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>Cloud Computing</span>
                        <h3>
                            <a routerLink="/blog-details"
                                >Harnessing the Power of Cloud Computing for
                                Business Success</a
                            >
                        </h3>
                        <p>
                            In recent years, cloud computing has emerged as a
                            game-changer for businesses of all sizes. The
                            ability to access computing...
                        </p>
                        <a
                            routerLink="/blog-details"
                            title="Read More"
                            class="link-btn"
                            ><i class="fas fa-arrow-right"></i
                        ></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
