import { Component, OnInit } from "@angular/core";
import { ViewportScroller } from "@angular/common";
import { ContactService } from "./contact.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    formStatusMessage: string | null = null;
    formStatusClass: string | null = null;
    submitted: boolean = false;

    constructor(
        private viewportScroller: ViewportScroller,
        private contactService: ContactService,
        private snackBar: MatSnackBar
    ) {}

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {}

    validateForm(form): void {
        if (!form.valid) {
            this.formStatusMessage = 'Please fill in all required fields.';
            this.formStatusClass = 'alert alert-danger';
            this.clearFormStatusMessageAfterTimeout();
        }
    }

    submit(form): void {
        this.submitted = true;
        if (form.valid) {
            this.contactService.sendContactForm(form.value).subscribe(
                (response) => {
                    console.log("Form submission success", response);
                    this.formStatusMessage = 'Message sent successfully!';
                    this.formStatusClass = 'alert alert-success';
                    form.resetForm();
                    this.submitted = false; 
                    this.clearFormStatusMessageAfterTimeout();
                },
                (error) => {
                    console.log("Form submission error", error);
                    this.formStatusMessage = 'Failed to send message. Please try again.';
                    this.formStatusClass = 'alert alert-danger';
                    this.clearFormStatusMessageAfterTimeout();
                }
            );
        } else {
            this.validateForm(form);
        }
    }
    clearFormStatusMessageAfterTimeout(): void {
        setTimeout(() => {
            this.formStatusMessage = null;
            this.formStatusClass = null;
        }, 4000); 
    }

}
