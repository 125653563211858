import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private apiUrl = 'https://desteksolutions.com/api/contactusapi.php';

  constructor(private http: HttpClient) {}

  sendContactForm(formData: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    const body = new URLSearchParams();
    body.set('name', formData.name);
    body.set('email', formData.email);
    body.set('phone', formData.number);
    body.set('subject', formData.subject);
    body.set('message', formData.message);

    return this.http.post(this.apiUrl, body.toString(), { headers });
  }
}
