<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Who We <span>Are</span></h2>
            <p>
                We deliver confidence that the people, processes, and
                technologies involved converge to create value in the future.
               
            </p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>Client-Centric Approach</h3>
                    <p>
                        They likely prioritize understanding client needs and
                        delivering tailored solutions that meet those needs
                        effectively.
                    </p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>Innovation and Technology</h3>
                    <p>
                        Given the nature of IT solutions, staying at the
                        forefront of technology and innovation is crucial.
                    </p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-tag"></i>
                    <h3>Agility and Adaptability</h3>
                    <p>
                        In the fast-paced IT industry, companies need to be
                        agile and adaptable.
                    </p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-hand-scissors"></i>
                    <h3>Quality and Reliability</h3>
                    <p>
                        Maintaining high standards of quality in their products
                        and services is likely a cornerstone of their strategy.
                       
                    </p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fab fa-linode"></i>
                    <h3>Employee Development</h3>
                    <p>
                        A skilled IT workforce invests in continuous training for technological advancements.
                    </p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-life-ring"></i>
                    <h3>Customer Support and Service</h3>
                    <p>
                        Post-sales service and support are critical in
                        maintaining long-term relationships with clients.
                    </p>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</div>
