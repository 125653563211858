<div class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently Asked <span>Question</span></h2>
            <p>Have Questions? Check Our FAQ for Answers.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <div class="accordion">
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(0)" [ngClass]="{ 'open': isSectionOpen(0) }">
                                What does Destek Infosolutions specialize in?

                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(0) }">
                                <p>Destek Infosolutions specializes in providing customized IT solutions tailored to meet the specific needs of businesses. Our services include custom software development, IT consulting, cloud solutions, cybersecurity, and managed IT services.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(1)" [ngClass]="{ 'open': isSectionOpen(1) }">
                                How can Destek Infosolutions help my business?

                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(1) }">
                                <p>We help businesses leverage technology to streamline operations, enhance efficiency, and achieve their strategic objectives. Whether you're looking to develop bespoke software, migrate to the cloud, strengthen cybersecurity measures, or optimize IT infrastructure, we have the expertise to support your goals.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(2)" [ngClass]="{ 'open': isSectionOpen(2) }">
                                What industries does Destek Infosolutions serve?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(2) }">
                                <p>We serve a diverse range of industries, including but not limited to healthcare, finance, retail, manufacturing, and education. Our solutions are adaptable to various industry-specific challenges and requirements</p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(3)" [ngClass]="{ 'open': isSectionOpen(3) }">
                                How experienced is the team at Destek Infosolutions?
                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(3) }">
                                <p>Our team consists of highly skilled professionals with extensive experience in their respective domains. We bring years of industry knowledge and expertise to every project, ensuring high-quality solutions and client satisfaction.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(4)" [ngClass]="{ 'open': isSectionOpen(4) }">
                                What is the process for engaging with Destek Infosolutions?

                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(4) }">
                                <p>The process typically begins with an initial consultation where we assess your needs and objectives. We then develop a tailored proposal outlining the scope, timeline, and cost of the project. Throughout the engagement, we maintain open communication to ensure alignment with your expectations and project milestones.
                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(5)" [ngClass]="{ 'open': isSectionOpen(5) }">
                                How does Destek Infosolutions ensure quality in its solutions?

                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(5) }">
                                <p>At Destek Infosolutions, we adhere to industry best practices and utilize advanced technologies to ensure the highest quality of our solutions. Our experienced team follows a rigorous development and testing process, maintaining strict quality control standards. We also collaborate closely with clients to understand their specific needs and deliver tailored solutions that exceed expectations.

                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(6)" [ngClass]="{ 'open': isSectionOpen(6) }">
                                Can you customize solutions to meet specific business needs?

                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(6) }">
                                <p>Yes, customization is a core aspect of our approach. We understand that each business has unique challenges and requirements. Our team works closely with clients to design and implement solutions that are specifically tailored to meet their objectives, ensuring a perfect fit for their operational needs.


                                </p>
                            </div>
                        </div>
                        <div class="accordion-section">
                            <div class="accordion-header" (click)="toggleSection(7)" [ngClass]="{ 'open': isSectionOpen(7) }">
                                How do I get started with Destek Infosolutions?

                            </div>
                            <div class="accordion-content" [ngClass]="{ 'open': isSectionOpen(7) }">
                                <p>Getting started with us is simple! You can reach out to our team through our contact page on our website, or give us a call at +918767426649. We'll set up a consultation to discuss your needs and how we can assist you in achieving your business goals.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-image.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</div>