<section class="feedback-area ptb-100">
    <div class="container">
        <div class="feedback-slides">
            <owl-carousel-o [options]="feedbackSlides">
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“At Destek Infosolutions, we believe that innovation is the key to unlocking limitless possibilities and driving meaningful change in every business we touch.”</p>
                        <div class="info">
                            <h3>Mandar Lande</h3>
                            <span>CEO, Destek Infosolutions
                            </span>
                            <img src="assets/img/Images-05.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“Empowering your vision with cutting-edge technology, we transform challenges into opportunities, inspiring growth and success every step of the way.”</p>
                        <div class="info">
                            <h3>Anirudha Kotgire</h3>
                            <span>Managing Director</span>
                            <img src="assets/img/Images-02.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template>
                <!-- <ng-template carouselSlide>
                    <div class="single-feedback-item">
                        <p>“If a man empties his purse into his head, no man can take it away from him. An investment in knowledge always pays the best interest.”</p>
                        <div class="info">
                            <h3>David Warner</h3>
                            <span>Switzerland</span>
                            <img src="assets/img/user3.jpg" class="shadow rounded-circle" alt="image">
                        </div>
                    </div>
                </ng-template> -->
            </owl-carousel-o>
        </div>
    </div>
</section>