<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <!-- <h4>Meet Addax</h4> -->
            <h2>Our Creative <span>Team</span></h2>
            <p>Meet Our Dedicated Team Committed to Delivering Excellence.</p>
        </div>
        <div class="team-slides">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Images-12.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Mandar Lande</h3>
                            <span class="post">Co-Founder & CEO</span>
                        </div>
                        <div class="social">
                            <ul>
                                <!-- <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li> -->
                                <li><a href="https://www.linkedin.com/in/mandarlande/" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Images-10.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Anirudha Kotgire</h3>
                            <span class="post">Managing Director</span>
                        </div>
                        <div class="social">
                            <ul>
                                <!-- <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li> -->
                                <li><a href="https://www.linkedin.com/in/anirudhakotgire/" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Images-11.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Shweta Dugad</h3>
                            <span class="post">Head - HR & Operations</span>
                        </div>
                        <div class="social">
                            <ul>
                                <!-- <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li> -->
                                <li><a href="https://www.linkedin.com/in/shweta-dugad-602b3a50/" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Images-06.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Nikhil Kamble</h3>
                            <span class="post">Project Manager
                            </span>
                        </div>
                        <div class="social">
                            <ul>
                                <!-- <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li> -->
                                <li><a href="http://www.linkedin.com/in/nikhil-kamble-bb828b199" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Images-03.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Pankaj Dhote</h3>
                            <span class="post">Chief Architect</span>
                        </div>
                        <div class="social">
                            <ul>
                                <!-- <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li> -->
                                <li><a href="https://www.linkedin.com/in/pankaj-dhote-774915a1/" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Images-07.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Shubham Bijwe</h3>
                            <span class="post">Operations Manager
                            </span>
                        </div>
                        <div class="social">
                            <ul>
                                <!-- <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li> -->
                                <li><a href="https://www.linkedin.com/in/shubham-bijwe-608784214/" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Images-14.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Chandrakant Jamdade</h3>
                            <span class="post">Head-Sales</span>
                        </div>
                        <div class="social">
                            <ul>
                                <!-- <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li> -->
                                <li><a href="https://www.linkedin.com/in/aakash-kamble-463231110/" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Images-13.png" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Kunal Thakur</h3>
                            <span class="post">Chief-Legal Counsel</span>
                        </div>
                        <div class="social">
                            <ul>
                                <!-- <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li> -->
                                <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/Images-01.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Akash Kamble</h3>
                            <span class="post">Head- Finance</span>
                        </div>
                        <div class="social">
                            <ul>
                                <!-- <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank" class="fab fa-instagram"></a></li> -->
                                <li><a href="https://www.linkedin.com/in/aakash-kamble-463231110/" target="_blank" class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>