<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <!-- <h4>We are creative</h4> -->
            <h2>Welcome to <span>destek</span></h2>
            <p>At Destek Infosolutions, we specialize in providing cutting-edge IT solutions tailored to meet the unique needs of businesses across various industries. Uses robust technology solutions that drive growth, efficiency, and success.
            </p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Customization</h3>
                    <p>We understand that every business is unique. That’s why we tailor our services to fit your specific requirements, ensuring solutions that are scalable, efficient, and cost-effective</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Quality Assurance</h3>
                    <p>We adhere to stringent quality standards to deliver solutions that meet and exceed expectations. Rigorous testing and validation processes ensure reliability and performance.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>Client-Centric Approach</h3>
                    <p>Building long-term relationships with our clients is our priority. We collaborate closely with you throughout the project lifecycle to ensure transparency, communication, and satisfaction.
                    </p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>