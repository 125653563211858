<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Services</span></h2>
            <p>We offer a range of services including technology consulting, UI/UX design, app development, web design, digital marketing, and IoT solutions, all tailored to meet your unique business needs and drive digital transformation.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-1.png" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-pencil-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Custom Software Development</h3>
                        <p>Transform your ideas into reality with our bespoke software development services. We craft scalable and secure solutions that align perfectly with your business objectives.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-2.png" alt="services-img">
                        <div class="icon">
                            <i class="fab fa-linode"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Web Development</h3>
                        <p> Build a strong online presence with our responsive and feature-rich web development services.From dynamic websites to complex web applications, we deliver solutions that engage.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-3.png" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-desktop"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Mobile App Development</h3>
                        <p>Tap into the mobile market with our custom mobile app development services. Whether you need iOS, Android, or cross-platform apps, we leverage the latest technologies.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-4.png" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-chart-line"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>IT Consulting</h3>
                        <p>Gain strategic insights and expert guidance with our IT consulting services. We help you navigate technological challenges, optimize processes, and achieve your business goals.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-5.png" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Digital Marketing</h3>
                        <p>Enhance your brand visibility and drive growth with our comprehensive digital marketing services.
                            From SEO and PPC to social media marketing, we develop strategies that maximize your ROI.
 
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-6.png" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-headphones-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Ui & Ux Design</h3>
                        <p>We deliver innovative technology and exceptional UI/UX design, ensuring a seamless user experience that surpasses expectations and sets new standards in customer satisfaction.


                        </p>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img-7.png" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-mobile-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Responsive Design</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img-8.png" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-camera-retro"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Photography</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img.png" alt="services-img">
                        <div class="icon">
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Custom Support</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>