<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/"><span>Destek</span></a></h3>
        <ul>
            <!-- <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li> -->
            <li><a href="https://www.linkedin.com/company/destek-solutions-private-limited/mycompany/" target="_blank" class="fab fa-linkedin-in"></a></li>
            <!-- <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li> -->
        </ul>
        <p>Copyright © 2024. All rights reserved to Destek infosolutions Pvt Ltd. </p>
    </div>
</footer>

<app-demo-sidebar></app-demo-sidebar>

<ngx-scrolltop></ngx-scrolltop>