<div class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Why We <span>Are Different</span></h2>
            <p>
                At Destek Infosolutions, we pride ourselves on being more than just another IT solutions provider. Here’s what sets us apart:</p>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="speed-flexibility" checked>
            <label for="tab1">Tailored Solutions</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="profeesional-work">
            <label for="tab2">Expertise and Experience</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="experienced-staff">
            <label for="tab3">Customer-Centric</label>
            <!-- Tab 4 -->
            <input type="radio" name="tabset" id="tab4" aria-controls="custom-support">
            <label for="tab4">Innovation and Adaptability</label>
            <div class="tab-panels">
                <div id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-1.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Tailored Solutions</h3>
                                <p>We understand that every business is unique. That’s why we offer bespoke IT solutions that are specifically tailored to meet your company’s needs. Whether you’re a startup looking to establish your IT infrastructure or a large enterprise seeking to optimize operations, we have the expertise to create solutions that fit.
                                </p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Expert Consultation and Strategy.</li>
                                    <li><i class="fas fa-check"></i>Customized IT Solutions.</li>
                                    <li><i class="fas fa-check"></i> Innovative Technology Integration.</li>
                                    <li><i class="fas fa-check"></i>Comprehensive Support Services.</li>
                                    <li><i class="fas fa-check"></i> Scalable Solutions for Growth.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="profeesional-work" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-2.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Expertise and Experience</h3>
                                <p>With years of experience in the industry, our team comprises seasoned professionals who excel in their respective domains. From software development to cybersecurity and cloud solutions, we deliver expertise that you can rely on.
                                </p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Proven Track Record.</li>
                                    <li><i class="fas fa-check"></i> Certified Professionals.</li>
                                    <li><i class="fas fa-check"></i> Industry Expertise.</li>
                                    <li><i class="fas fa-check"></i> Innovative Solutions.</li>
                                    <li><i class="fas fa-check"></i> Client-Centric Approach.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="experienced-staff" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-5.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Customer-Centric Approach</h3>
                                <p>Our clients are at the heart of everything we do. We prioritize understanding your challenges and goals to provide solutions that drive your business forward. Your success is our success, and we’re committed to building long-term partnerships based on trust and mutual growth.
                                </p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Personalized Service.</li>
                                    <li><i class="fas fa-check"></i> Proactive Communication.</li>
                                    <li><i class="fas fa-check"></i> 24/7 Support.</li>
                                    <li><i class="fas fa-check"></i>Client Feedback Integration.</li>
                                    <li><i class="fas fa-check"></i> Long-Term Partnerships.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="custom-support" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-6.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Innovation and Adaptability</h3>
                                <p>In the rapidly evolving world of technology, innovation is key. We stay ahead of the curve by embracing emerging trends and technologies, ensuring that your solutions are not only cutting-edge but also future-proof</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Cutting-Edge Technology.</li>
                                    <li><i class="fas fa-check"></i> Agile Methodologies.</li>
                                    <li><i class="fas fa-check"></i> Continuous Improvement.</li>
                                    <li><i class="fas fa-check"></i> Future-Ready Solutions.</li>
                                    <li><i class="fas fa-check"></i> Rapid Response to Trends.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>