<div id="contact" class="contact-area ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h4>Get in Touch</h4>
            <h2><span>Contact</span> Us</h2>
            <p>Connect with Us for Personalized Support and Solutions.</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form
                        #contactForm="ngForm"
                        (ngSubmit)="submit(contactForm)"
                        novalidate
                    >
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Name</label>
                                    <input
                                        required
                                        minlength="3"
                                        maxlength="50"
                                        ngModel
                                        name="name"
                                        type="text"
                                        #name="ngModel"
                                        class="form-control"
                                        id="name"
                                    />
                                    <div
                                        class="alert alert-danger"
                                        *ngIf="name.invalid && (name.touched || submitted)"
                                    >
                                        <div *ngIf="name.errors?.required">
                                            Name is required.
                                        </div>
                                        <div *ngIf="name.errors?.minlength">
                                            Name must be at least {{
                                                name.errors.minlength.requiredLength
                                            }} characters long.
                                        </div>
                                        <div *ngIf="name.errors?.maxlength">
                                            Name cannot be more than 50 characters long.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email</label>
                                    <input
                                        required
                                        ngModel
                                        name="email"
                                        type="email"
                                        #email="ngModel"
                                        class="form-control"
                                        id="email"
                                    />
                                    <div
                                        class="alert alert-danger"
                                        *ngIf="email.invalid && (email.touched || submitted)"
                                    >
                                        <div *ngIf="email.errors?.required">
                                            Email is required.
                                        </div>
                                        <div *ngIf="email.errors?.email">
                                            Invalid email address.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Subject</label>
                                    <input
                                        required
                                        ngModel
                                        name="subject"
                                        type="text"
                                        #subject="ngModel"
                                        class="form-control"
                                        id="subject"
                                    />
                                    <div
                                        class="alert alert-danger"
                                        *ngIf="subject.invalid && (subject.touched || submitted)"
                                    >
                                        Subject is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Phone Number</label>
                                    <input
                                        required
                                        ngModel
                                        name="number"
                                        type="text"
                                        #number="ngModel"
                                        class="form-control"
                                        id="number"
                                    />
                                    <div
                                        class="alert alert-danger"
                                        *ngIf="number.invalid && (number.touched || submitted)"
                                    >
                                        Phone number is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Message</label>
                                    <textarea
                                        required
                                        ngModel
                                        #message="ngModel"
                                        name="message"
                                        id="message"
                                        cols="30"
                                        rows="4"
                                        class="form-control"
                                    ></textarea>
                                    <div
                                        class="alert alert-danger"
                                        *ngIf="message.invalid && (message.touched || submitted)"
                                    >
                                        Message is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button
                                    type="submit"
                                    class="btn btn-primary"
                                    
                                    (click)="submitted = true"
                                >
                                    Send Message
                                </button>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <div *ngIf="formStatusMessage" [ngClass]="formStatusClass">
                                    {{ formStatusMessage }}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li>
                                    <i class="fas fa-map-marker-alt"></i>
                                    <span>Location:</span> Office no 202B, Town
                                    Square, Off New Airport Road, Viman Nagar,
                                    Pune 411014
                                </li>
                                <li>
                                    <i class="far fa-envelope"></i>
                                    <a href="mailto:contactus&#64;desteksolutions.com"
                                        ><span>Email:</span
                                        >contactus&#64;desteksolutions.com</a
                                    >
                                </li>
                                <li>
                                    <i class="fas fa-phone-alt"></i>
                                    <a href="tel:+918767426649"
                                        ><span>Call:</span>(+91) 8767426649</a
                                    >
                                </li>
                                <!-- <li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
