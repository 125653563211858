<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>Looking for exclusive services?</h4>
                <h2>Get The Best For Your Business</h2>
                <p>
                    We help businesses leverage technology to streamline operations, enhance efficiency, and achieve their strategic objectives.</p>
                <a (click)="onClick('contact')" class="btn btn-primary">Contact Us</a>
                <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a>
            </div>
        </div>
    </div>
</div>